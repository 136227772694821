<script>

import {firstToUpperCase, getSocieteInformationsFromForm, isEmpty, isUndef} from "@/data/helpers";

export default {
  data() {
    return {
      form: {},
      texteAnnonce: "",
      model: {},
      schema: [],
      texte: "",
      title: "",
      submitting: false
    }
  },
  watch: {
    // model: {
    //   handler() {
    //     const key = Object.keys(this.form)[0];
    //     if (this.form[key].model) {
    //       Object.assign(this.model, this.form[key].model);
    //     }
    //
    // //     this.texteAnnonce = this.generateRedactionTexte();
    //   },
    //   deep: true
    // },
    schema: {
      handler(newVal) {
        const key = Object.keys(this.form)[0];
        for (let i = 0; i < newVal.length; i++) {
          if (newVal[i].type !== 'group') continue;
          const nameGroup = newVal[i].name;
          this.model[nameGroup] = newVal[i].value;
        }
        if (this.form[key].model) {
          Object.assign(this.model, this.form[key].model);
        }
        this.texteAnnonce = this.generateRedactionTexte();
      },
      deep: true
    }
  },
  methods: {
    generateRedactionTexte() {
      const schema = this.schema;
      const model = this.model;
      const form = this.form;
      let text = this.texte;

      /* Set clé = valeur */
      let varText = {};
      for (let i = 0; i < schema.length; i++) {
        if (isUndef(schema[i].children)) continue;
        for (let j = 0; j < schema[i].children.length; j++) {
          if (isUndef(model[schema[i].name])) continue;
          const nameGroup = schema[i].name;
          const nameAttribute = schema[i].children[j].name;
          const namePath = nameGroup + "." + nameAttribute;

          const isNotHidden = schema[i].children[j].type !== 'hidden';
          if (!isNotHidden) {
            varText[namePath] = '';
            continue;
          }

          const groupIsRepeatable = !isUndef(schema[i].repeatable) && schema[i].repeatable;
          if (groupIsRepeatable && isUndef(varText[nameGroup])) {
            varText[nameGroup] = model[nameGroup];
            continue;
          }

          const haveSelectValue = !isUndef(schema[i].children[j].values);
          varText[namePath] = haveSelectValue ?
              schema[i].children[j].values[model[nameGroup][0][nameAttribute]] ||
              model[nameGroup][0][nameAttribute] || ''
              : model[nameGroup][0][nameAttribute] || '';

        }
      }

      /* replace [[clé.clé]] par valeur || '' */
      let regexBracket = text.match(/\[\[([^[\]]*)]]/);
      while (regexBracket !== null) {
        const regex = regexBracket[0].match(/{{([^}]*)}}/);
        const customScript = form[Object.keys(form)[0]].script;
        if (typeof customScript !== "undefined") {
          let customResult = undefined;
          if (!isEmpty(customScript(text, varText, regex[1]))) {
            customResult = regexBracket[1].replace(regex[0], customScript(text, varText, regex[1]));
          }
          const result = isEmpty(varText[regex[1]]) ? undefined : regexBracket[1].replace(regex[0], `<span class='user-value'>${varText[regex[1]]}</span>`);
          const replace = customResult || result || '';

          text = text.replaceAll(regexBracket[0], replace);
        } else {
          text = text.replaceAll(regexBracket[0], '');
        }
        regexBracket = text.match(/\[\[([^[\]]*)]]/);
      }

      /* replace clé.clé par valeur || '' */
      let varTextRegex = [...text.matchAll(/{{2}([^}]*)}{2}/g)];
      for (let i = 0; i < varTextRegex.length; i++) {
        const customScript = form[Object.keys(form)[0]].script;
        if (typeof customScript !== "undefined") {
          const customResult = customScript(text, varText, varTextRegex[i][1]);
          const result = isEmpty(varText[varTextRegex[i][1]]) ? undefined : `<span class='user-value'>${varText[varTextRegex[i][1]]}</span>`;

          text = text.replaceAll(varTextRegex[i][0], customResult || result || '');
        }
      }

      // text = replaceAll(text, ['null', 'undefined', '{{', '}}', '[[', ']]', ' ,'], ['', '', '', '', '', '', ', ']);
      text = text.replaceAll(/ {2,}/ig, ' ');
      text = firstToUpperCase(text);
      return text;
    },
    submitForm() {
      this.submitting = true;
      // const data = {
      //   application_id: `${process.env.APPLICATION_ID}`,
      //   api_key: `${process.env.API_KEY}`,
      //   journal: `${process.env.JOURNAL}`,
      //   date_parution: "2017-04-06",
      //   date_plus_proche: true,
      //   departement: "06",
      //   nb_justificatifs: "5",
      //   reference: "Non reiciendis unde non.",
      //   expedition: {
      //     civilite: "Me.",
      //     nom: "Moreno",
      //     prenom: "Aurore",
      //     adresse: "19, place de Descamps",
      //     code_postal: "64800",
      //     ville: "Chevaliernec",
      //     tel: "0164680987",
      //     email: "fabre.augustin@example.org"
      //   },
      //   facturation: {
      //     civilite: "Mlle",
      //     nom: "Maillet",
      //     prenom: "Martin",
      //     adresse: "chemin de Hernandez",
      //     code_postal: "00290",
      //     ville: "David-les-Bains",
      //     tel: "0666659637",
      //     email: "jean.margaud@example.org"
      //   },
      //   annonce: {
      //     logo: "https:\/\/www.regie.pro\/assets\/img\/logo-prolegal.png",
      //     logo_base_64: "Omnis asperiores velit qui est.",
      //     donneur_ordre_titre: "Cabinet Me. Guillou S.A.S.",
      //     donneur_ordre_texte: "15, avenue Georges Fontaine 77672 Lebreton",
      //     entete_titre: "Gomez SARL",
      //     entete_texte: "rue Denis Fernandes 60988 WeberVille",
      //     societe_siren: {
      //       type_annonce: "Additif \/ Rectificatif",
      //       format_eco: "Oui",
      //       denomination_eco: "Carre Gomez S.A.R.L."
      //     },
      //     redaction: {
      //       texte: "<p>Suivant acte SSP du 04\/10\/2015, la société<\/p> <h1>Monsieur BRIQUET Gérard<\/h1> <p>sis 9bis rue de l'église , 62860 Quéant a confié en location-gérance à<\/p> <h1>Madame DELVARRE Fanny<\/h1> <p>son fond de commerce de : bar sis et exploité au 9 rue de l'église 62860 QUÉANT La présente location-gérance est consentie et acceptée pour une durée de 12 mois <strong>à compter du 04\/10\/2015<\/strong> pour <strong>se terminer le 04\/10\/2016<\/strong>, Par tacite reconduction.<\/p>"
      //     }
      //   }
      // }
      const dataSociete = getSocieteInformationsFromForm(this.model);
      let dataToSend = {devis: this.texteAnnonce};

      if (dataSociete !== false) {
        dataToSend = Object.assign(dataSociete, {devis: this.texteAnnonce});
      }

      window.top.postMessage(dataToSend, '*');
      console.log(dataToSend);

      this.submitting = false;

    },
    init(form) {
      const key = Object.keys(form)[0];
      this.model = form[key].model || {};
      this.texte = form[key].texte;
      this.title = form[key].title;/*
      if (form[key].schema[form[key].schema.length - 1].type !== "submit")
        form[key].schema.push({type: "submit", label: "Obtenir le meilleur tarif"});*/
      this.schema = form[key].schema || {};
    },
  },
  async mounted() {
    const formName = this.$route.params.formName || localStorage.getItem('form-name');
    if (formName === "undefined" || formName === undefined || formName === null || formName === "") {
      localStorage.removeItem('form-name');
      await this.$router.push('/');
    } else {
      try {
        this.form = await import('../data/forms' + formName);
        this.init(this.form);
        localStorage.setItem('form-name', formName);
      } catch (e) {
        localStorage.removeItem('form-name');
        console.error("file " + formName + ".js does not exist", e);
        await this.$router.push('/');
      }
    }
  }
}

</script>


<template>
  <div>
    <h1>{{ title }}</h1>
    <div class="form-content">
      <b-row class="w-100 m-0">
        <b-col cols="12" class="p-0">
          <FormulateForm v-model="model" :schema="schema" name="myForm"/>
        </b-col>
        <b-col cols="12" class="p-0">
          <hr/>
          <div class="preview">
            <div class="preview-votre-annonce">Votre annonce</div>
            <div v-html="texteAnnonce"></div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center mt-4">
          <b-button id="submitForm" @click="submitForm()" :disabled="submitting">
            <b-spinner small v-if="submitting" class="me-2"></b-spinner>
            <span class="formulate-input-element--submit--label">{{ submitting ? "Recherche du meilleur tarif" : "Obtenir le meilleur tarif" }}</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>


<style>
/* Beaucoup mieux mais le css n'est pas au top */
</style>