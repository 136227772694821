import {rules} from "./rules";
export const cloneDeep = require("lodash.clonedeep");


export function visible(schemaDef) {
    schemaDef.label = schemaDef.labelSave;
    schemaDef.type = schemaDef.typeSave;
    schemaDef.validation = schemaDef.validationSave;
    schemaDef.validationMessages = schemaDef.validationMessagesSave;
    return schemaDef;
}

export function optional(schemaDef) {
    schemaDef["label-class"] = [];
    schemaDef.validation = '';
    schemaDef.validationMessages = {};
    return schemaDef;
}

export function hidden(schemaDef) {
    schemaDef.label = '';
    schemaDef.type = "hidden";
    schemaDef.validation = '';
    schemaDef.validationMessages = {};
    return schemaDef;
}

export function visibility(input, value, ...compare) {
    for (let i = 0; i < compare.length; i++) {
        if(value === compare[i]) {
            return visible(input);
        }
    }
    return hidden(input);
}

export function newInput(schemaDef, rule = '', fields = {}, visibility = 'visible', /*, ...othersObj*/) {
    let newDef = cloneDeep(schemaDef);

    if (fields !== {}) {
        newDef = Object.assign(newDef, fields);
    }

    /* rules input */
    const rulesSplit = rule.split('|');
    let validMsg = {};

    if (rule.includes('_required') || rule.includes('_validUniqueSelect')) {
        newDef["label-class"] = ["required-field-label"];
    }

    for (let i = 0; i < rulesSplit.length && rulesSplit[i] !== ''; i++) {
        if (!rules[rulesSplit[i]]) {
            console.error("rules '" + rulesSplit[i] + "' not recognized");
            continue;
        }
        validMsg[rulesSplit[i]] = rules[rulesSplit[i]].validationMessages[rulesSplit[i]];
    }
    newDef.validation = rule || '';
    newDef.validationMessages = validMsg;


    /* sauvegarde des attributs */
    newDef.labelSave = schemaDef.label;
    newDef.typeSave = schemaDef.type;
    newDef.validationSave = newDef.validation;
    newDef.validationMessagesSave = validMsg;

    /* state */
    if (visibility === 'hidden' || visibility === 'h') {
        newDef = hidden(newDef);
    } else if (visibility === 'disabled') {
        newDef.disabled = true;
    }

    return newDef;
}


export function isEmpty(obj) {
    return obj === '' || obj === null || obj === undefined || Object.keys(obj).length === 0;
}

export function isUndef(obj) {
    return typeof obj === "undefined";
}


export function cssVal(value) {
    if (!isEmpty(value)) {
        return `<span class='user-value'>${value}</span>`
    }
    return '';
}


export function getDataFromApi(url, headers) {
    return fetch(url, {headers});
}

export function getDateNow() {
    return new Date().toISOString().substring(0, 10);
}

export function tryToDate(value) {
    if (value === null) return '';
    const date = new Date(value);
    return date.toString() === 'Invalid Date' ? value : date.toLocaleDateString("fr");
}


/* Outils */
export function replaceAll(string, models, replacements = []) {
    for (let i = 0; i < models.length; i++) {
        string = string.replaceAll(models[i], replacements[i] || '');
    }
    return string;
}

export function firstToUpperCase(string) {
    if (typeof string !== 'string') return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function updateSchema(form) {
    form.schema.push({name: 'reload', value: true});
    form.schema.pop();
    return form.schema;
}

export async function getDataBySiren(siren) {
    const url = `https://hjegraft.preprod.lexiktrac.com/private/api/v1/companies/${siren}.json?_format=json`;
    const headers = {
        "Accept": "application/json",
        "Authorization": `Basic ${Buffer.from(`${process.env.VUE_APP_API_SIREN_USERNAME}:${process.env.VUE_APP_API_SIREN_PASSWORD}`, "utf-8").toString("base64")}`
    };

    const result = await getDataFromApi(url, headers);
    const data = await result.json();

    const formeSociete = getFormeSocieteFromLabel(data.legalFormLabel);

    return {
        values: {
            societe: {
                societeCapital: isNaN(data.capital) ? '' : data.capital.toString(),
                societeDenomination: data.denomination,
                societeFormeJuridique: formeSociete ? formeSociete.value : "",
                societeRcs: data.siren,
                societeRcsVille: data.graftLabel,
            },
            siegeSocial: {
                societeAdresse: data.shortAddress,
                societeAdresse2: "",
                societeCodePostal: data.addressPostalCode,
                societeVille: data.addressOfficeSupply,
            },
            all: {
                montantCapital: isNaN(data.capital) ? '' : data.capital.toString(),
                denomination: data.denomination,
                formesSocietes: formeSociete ? formeSociete.value : "",
                rcs: data.siren,
                lieuImmatriculation: data.graftLabel,
                adresse: data.shortAddress,
                adresseSuite: "",
                codePostal: data.addressPostalCode,
                ville: data.addressOfficeSupply,
            }
        },
        result: data
    }

}

export async function getDataByCodePostal(codePostal, villeToSelect = null) {
    const url = `https://www.regie.pro/villes/recherche/`+ codePostal;
    const headers = {
        "Accept": "application/json",
    };

    const result = await getDataFromApi(url, headers);
    const data = await result.json();

    if (data === []) {
        return {}
    }

    let options = [];
    let ville_slug = null;
    for (let i = 0; i < data.length; i++) {
        if (data[i].ville_nom === villeToSelect) {
            ville_slug = data[i].ville_slug;
        }

        options.push({
            value: data[i].ville_slug,
            label: data[i].ville_nom_reel
        });
    }

    if (ville_slug === null && data.length === 1) {
        ville_slug = data[0].ville_slug;
    }

    return {
        options: options, //options des villes
        ville_slug: ville_slug, // ville || null
        greffe: data[0].greffe
    };
}


/* Rédaction texte */

export function texteForPersonnePhysiqueOuMoral(varText, nameGroup) {
    if (!isUndef(varText[nameGroup + '.typePersonne']) && varText[nameGroup + '.typePersonne'] === 'morale') {
        if (isEmpty(varText[nameGroup + ".rcs"]) && isEmpty(varText[nameGroup + ".denomination"]) && isEmpty(varText[nameGroup + ".lieuImmatriculation"]))
            return '';

        return ` ${cssVal(varText[nameGroup + ".denomination"]) || ''} ${isEmpty(varText[nameGroup + ".sigle"]) ? '' : '(' + cssVal(varText[nameGroup + ".sigle"]) + ')'} <span class='user-value'>${varText[nameGroup + ".formesSocietes"] || ''}</span> Capital : ${cssVal(varText[nameGroup + ".montantCapital"])} €, <span class='user-value'>${varText[nameGroup + ".adresse"] || ''} ${varText[nameGroup + ".adresseSuite"] || ``} ${varText[nameGroup + ".codePostal"] || ''} ${varText[nameGroup + ".ville"] || ''}</span>, Rcs <span class='user-value'>${varText[nameGroup + ".lieuImmatriculation"] || ''} ${varText[nameGroup + ".rcs"] || ''}</span>`
    }

    if (isEmpty(varText[nameGroup + ".civilite"]) && isEmpty(varText[nameGroup + ".nom"]) && isEmpty(varText[nameGroup + ".prenom"]))
        return '';

    return ` <span class='user-value'>${texteForCivilite(varText[nameGroup + ".civilite"])} ${varText[nameGroup + ".prenom"] || ''} ${varText[nameGroup + ".nom"] || ''}, ${varText[nameGroup + ".adresse"] || ''} ${varText[nameGroup + ".adresseSuite"] || ``} ${varText[nameGroup + ".codePostal"] || ''} ${varText[nameGroup + ".ville"] || ''} </span>`;
}


export function texteForGerances(varText, nameGroup = 'gerancesGroup') {
    return texteForPersonnePhysiqueOuMoral(varText, nameGroup);
}

export function texteForAssocies(varText, nameGroup = 'associesGroup') {
    return texteForPersonnePhysiqueOuMoral(varText, nameGroup);
}

export function texteForPresident(varText, nameGroup = 'presidentGroup') {
    return texteForPersonnePhysiqueOuMoral(varText, nameGroup);
}

export function texteForDirecteurGeneral(varText, nameGroup = 'directeurgeneralGroup') {
    return texteForPersonnePhysiqueOuMoral(varText, nameGroup);
}


export function texteForSiege(varText, nameGroup = 'siegeSocialGroup') {
    return ` <span class='user-value'>${varText[nameGroup + ".adresse"] || ''} ${varText[nameGroup + ".adresseSuite"] || ''} ${varText[nameGroup + ".codePostal"] || ''} ${varText[nameGroup + ".ville"] || ``} </span>`;
}

export function texteForCessionTransmissionAction(varText, nameGroup = 'cessionTransmissionActionGroup') {
    if(varText[nameGroup +'.typeTransmissionAction'] === 'autre') {
        return `${cssVal(varText[nameGroup +'.autre'])}`;
    }
    return `${cssVal(varText[nameGroup +'.typeTransmissionAction'])}`;
}

export function texteForAdmissionAssemble(varText, nameGroup = 'admissionAssembleGroup') {
    if (varText[nameGroup + '.typeAdmission'] === 'autre') {
        return `${cssVal(varText[nameGroup + '.autre'])}`;
    }
    return `${cssVal(varText[nameGroup + '.typeAdmission'])}`;
}

export function texteForCessionPart(varText, nameGroup = 'cessionPartGroup') {
    if (varText[nameGroup + '.typeCessionPart'] === 'autre') {
        return `${cssVal(varText[nameGroup + '.autre'])}`;
    }
    return `${cssVal(varText[nameGroup + '.typeCessionPart'])}`;
}

export function texteForCapital(varText) {
    if (varText["societyGroup.capitalInitial"] === null || varText["societyGroup.capitalInitial"] === '' || varText["societyGroup.capitalInitial"] === undefined) {
        return ` ${cssVal(varText["societyGroup.montantCapital"])}</span> €`
    }
    return `variable, initial : ${cssVal(varText["societyGroup.capitalInitial"])} €, minimum : ${cssVal(varText["societyGroup.capitalMinimum"])} €`;
}

export function texteForNatureActe(varText, nameGroup = 'acteGroup') {
    return `Suivant ${cssVal(varText[nameGroup + '.natureActe'])}, en date du ${cssVal(tryToDate(varText[nameGroup + '.dateActe']))}, à ${cssVal(varText[nameGroup + '.lieuActe'])}, ${isEmpty(varText[nameGroup + '.recuPar']) ? '' : `reçu par Me ${cssVal(varText[nameGroup + '.recuPar'])},`}`;
}

export function texteForCivilite(value) {
    if (value === 'homme') {
        return cssVal('M.');
    } else if (value === 'femme') {
        return cssVal('Mme');
    }
    return '';
}

export function getFormeSocieteFromLabel(label) {
    const formesSociete = [
        {value: "sa",       label: "Société anonyme"},
        {value: "sad",      label: "Société anonyme à directoire"},
        {value: "saca",     label: "Société anonyme à conseil d'administration"},
        {value: "saemd",    label: "Société anonyme d'économie mixte à directoire"},
        {value: "saemcd",   label: "Société anonyme d'économie mixte à conseil d'administration"},
        {value: "sahd",     label: "Société anonyme de HLM à directoire"},
        {value: "sahca",    label: "Société anonyme de HLM à conseil d'administration"},
        {value: "sarl",     label: "Société à responsabilité limitée"},
        {value: "sarlau",   label: "Société à responsabilité limitée à associé unique"},
        {value: "sarlu",    label: "Société à responsabilité limitée unipersonnelle"},
        {value: "sas",      label: "Société par actions simplifiée"},
        {value: "sasau",    label: "Société par actions simplifiée à associé unique"},
        {value: "sc",       label: "Société civile"},
        {value: "sci",      label: "Société civile immobilière"},
        {value: "scm",      label: "Société civile de moyens"},
        {value: "scp",      label: "Société civile professionnelle"},
        {value: "scea",     label: "Société civile d'exploitation agricole"},
        {value: "selrl",    label: "Société d'exercice libéral à responsabilité limitée"},
        {value: "selas",    label: "Société d'exercice libéral par actions simplifiées"},
        {value: "selfa",    label: "Société d'exercice libéral à forme anonyme"},
        {value: "snc",      label: "Société en nom collectif"},
        {value: "ste",      label: "Société technique équipement"},
        {value: "scorl",    label: "Société coopérative ouvrière à responsabilité limitée"},
    ];

    for (const i in formesSociete) {
        if (formesSociete[i].label === label) {
            return formesSociete[i];
        }
    }

    return null;
}

export function getSocieteInformationsFromForm(values) {
    let informations = {};
    const correspondance = {
        societeCapital: "capital",
        societeInitialCapital: "capital",
        societeDenomination: "denomination",
        societeFormeJuridique: "forme_juridique",
        societeRcs: "rcs_num",
        societeRcsVille: "rcs_ville",
        societeAdresse: "adresse1",
        societeAdresse2: "adresse2",
        societeCodePostal: "codepostal",
        societeVille: "ville",
    };

    for (const groupKey in values) {
        for (const attributeName in values[groupKey][0]) {
            const keyToSet = correspondance[attributeName] ?? "";

            if (keyToSet !== "") {
                informations[keyToSet] = values[groupKey][0][attributeName] ?? '';
            }
        }
    }

    return informations;
}
